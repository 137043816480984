<template>
  <div class="page index">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item,xh) in poemFilter" :key="xh">
        <div class="img"><img :src="item.img"></div>
        <div class="text">
          <div class="poemT f56 ellipsis1">{{item.poem}}</div>
          <div class="poetT f18">{{getDynasty(item.poet)}}·<i>
            <router-link :to="'/poet/'+getPoetId(item.poet)">{{item.poet}}</router-link>
          </i></div>
          <div class="content f28" v-html="jie(item.content)"></div>
          <router-link :to="'/poem/'+item.id" class="see f24">查看</router-link>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
    </swiper>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import "swiper/css/swiper.css";
// import {updateAll} from '@/api/api'
export default {
    name: 'poem',
    components: {
        Swiper, SwiperSlide
    },
    data() {
        return {
            exp: false,
            poet: [],
            poem: [],
            swiperOptions: {
                autoplay: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        }
    },
    created() {

        let formData3 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poem',
            perpage: 10000,
            order: ["id DESC"]
        }
        let formData4 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poet',
            perpage: 10000,
            order: ["id DESC"]
        }


        if (this.$store.state.articles.poem.length === 0) {
            this.$store.dispatch('articles/getList', formData3).then((rs) => {
                this.poem = rs
            })
        } else {
            this.poem = this.$store.state.articles.poem
        }
        if (this.$store.state.articles.poet.length === 0) {
            this.$store.dispatch('articles/getList', formData4).then((rs) => {
                this.poet = rs
            })
        } else {
            this.poet = this.$store.state.articles.poet
        }
        // let formData1 = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'poem',
        //     where: ["tags = '送'"],
        //     data:{"tags":'送别诗'}
        // }
        //  updateAll(formData1)


    },
    methods: {
        getDynasty(text) {
            let fi = this.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].dynasty
            }
        },
        getPoetId(text) {
            let fi = this.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].id
            } else {
                return ''
            }
        },
        jie(html) {

            let reg1 = new RegExp('([0-9]|[\u2460-\u249B])+', 'gi')
            html = html.replace(reg1, '')
            return html
        },
    },
    computed: {
        poemFilter() {
            let lastData = this.poem.filter((el) => {
                return el.img
            })
            return lastData

        },
    },
    watch: {},
}
</script>

